
import { Options, Vue } from 'vue-class-component';

import {
  MIN_IMAGE_SIZE,
  MAX_IMAGE_SIZE,
  MIN_IMAGE_FILE_SIZE,
  MAX_IMAGE_FILE_SIZE,
  EEntityType
} from '@/system/constants';

import {ClientsService} from '@/system/services/ClientsService';
import {
  IImage,
  ImagesService
} from '@/system/services/ImagesService';
import {ICommentary, CommentariesService} from '@/system/services/CommentariesService';
import {IVote, EVotes, VotesService} from '@/system/services/VotesService';

import {LocalStorage} from '@/system/common_code/Utilities';

@Options({
  props: {
    id: String
  },

  // data: () => ({
  //   m_doesBelongToClient: false
  // }),

  async created () {
    (window as any).btShowUpdateCommentaryControls_OnClick = this.btShowUpdateCommentaryControls_OnClick;
    (window as any).btDeleteCommentary_OnClick = this.btDeleteCommentary_OnClick;

    await this.update ();
    await this.updateVotesControls ();
    await this.updateCommentariesControls ();
    await this.updateVotes ();
    await this.updateCommentaries ();
  },

  methods: {
    async update () {
      // const isLoggedIn = ClientsService.isLoggedIn ();

      // console.log (`Is client logged in is : ${isLoggedIn}.`);
      // if (isLoggedIn) {
        const image = await ImagesService.get (this.$route.params.id);
        console.log (image);
        const h2Caption = document.getElementById ("h2Caption");
        if (image && h2Caption) {
          document.title = `Изображение ${image.name} - Фото-Фитнес-Студия ArtPro`;
          h2Caption.innerHTML = `Изображение ${image.name}`;

          const divImagesControls = document.getElementById ("divImagesControls");
          const edImageName = document.getElementById ("edImageName") as HTMLInputElement;
          const edImageDescription = document.getElementById ("edImageDescription") as HTMLInputElement;
          const edImageLocation = document.getElementById ("edImageLocation") as HTMLInputElement;
          const divUploadImage = document.getElementById ("divUploadImage") as HTMLInputElement;
          const divGalleryLink = document.getElementById ("divGalleryLink");
          const linkImageControls = document.getElementById ("linkImageControls");
          const spanMinImageSize = document.getElementById ("spanMinImageSize");
          const spanMaxImageSize = document.getElementById ("spanMaxImageSize");
          const spanMinImageFileSize = document.getElementById ("spanMinImageFileSize");
          const spanMaxImageFileSize = document.getElementById ("spanMaxImageFileSize");
          if (divImagesControls &&
              edImageName && edImageDescription && edImageLocation &&
              divUploadImage &&
              divGalleryLink && linkImageControls &&
              spanMinImageSize && spanMaxImageSize &&
              spanMinImageFileSize && spanMaxImageFileSize
          ) {
            // console.log (`Images is : ${JSON.stringify (image)}.`);
            divImagesControls.style.display = image.doesBelongToClient ? "block" : "none";

            edImageName.value = image.name;
            edImageDescription.value = image.description;
            edImageLocation.value = image.location;
            divUploadImage.style.display = ! image.path ? "block" : "none";

            const idGallery = LocalStorage.getValue ("idGallery");
            divGalleryLink.style.display = idGallery ? "inline" : "none";
            // linkImageControls.style.display = idGallery ? "inline" : "none";
            linkImageControls.setAttribute ("href", `/galleries/${idGallery}`);

            spanMinImageSize.innerHTML = `${MIN_IMAGE_SIZE}`;
            spanMaxImageSize.innerHTML = `${MAX_IMAGE_SIZE}`;
            spanMinImageFileSize.innerHTML = `${MIN_IMAGE_FILE_SIZE}`;
            spanMaxImageFileSize.innerHTML = `${MAX_IMAGE_FILE_SIZE}`;
          }

          this.updateImage (image);
          this.updateRating (image.rating);
          this.updateClient (image.idOwner);
        }
      // }
    },

    async updateImage (inputImage: IImage | undefined) {
      // const isLoggedIn = ClientsService.isLoggedIn ();

      // if (isLoggedIn) {
        const image = inputImage || await ImagesService.get (this.$route.params.id);
        if (image) {
          const divImage = document.getElementById ("divImage");
          if (divImage) {
            divImage.style.display = image.pathURL ? "block" : "none";
            divImage.innerHTML = `<img style="max-width: 100%;" src="${image.pathURL ? image.pathURL : ""}"/>`;
          }
        }
      // }
    },

    updateRating (inputRating: number) {
      const spanRating = document.getElementById ("spanRating");
      if (spanRating) {
        spanRating.innerHTML = `${inputRating}`;
      }
    },

    async updateVotesControls () {
      const spanVotesPlusText = document.getElementById ("spanVotesPlusText");
      const spanVotesMinusText = document.getElementById ("spanVotesMinusText");
      const spanVotesComplainsText = document.getElementById ("spanVotesComplainsText");
      const btVotesPlus = document.getElementById ("btVotesPlus");
      const btVotesMinus = document.getElementById ("btVotesMinus");
      const btVotesComplains = document.getElementById ("btVotesComplains");
      if (spanVotesPlusText && spanVotesMinusText && spanVotesComplainsText &&
          btVotesPlus && btVotesMinus && btVotesComplains
      ) {
        const isLoggedIn = ClientsService.isLoggedIn ();
        spanVotesPlusText.style.display = isLoggedIn ? "none" : "inline";
        spanVotesMinusText.style.display = isLoggedIn ? "none" : "inline";
        spanVotesComplainsText.style.display = isLoggedIn ? "none" : "inline";
        btVotesPlus.style.display = isLoggedIn ? "inline-block" : "none";
        btVotesMinus.style.display = isLoggedIn ? "inline-block" : "none";
        btVotesComplains.style.display = isLoggedIn ? "inline-block" : "none";
      }
    },

    async updateVotes () {
      const spanVotesPlus = document.getElementById ("spanVotesPlus");
      const spanVotesMinus = document.getElementById ("spanVotesMinus");
      const spanVotesComplains = document.getElementById ("spanVotesComplains");
      const divVotesLog = document.getElementById ("divVotesLog");
      if (spanVotesPlus && spanVotesMinus && spanVotesComplains &&
          divVotesLog
      ) {
        const votes = await VotesService.getVotes (this.$route.params.id, EEntityType.Image);
        console.log (votes);
        if (votes) {
          spanVotesPlus.innerHTML = `${votes.data.amountPlusVotes}`;
          spanVotesMinus.innerHTML = `${votes.data.amountMinusVotes}`;
          spanVotesComplains.innerHTML = `${votes.data.amountComplainsVotes}`;
          this.updateRating (votes.data.rating);
          this.divVotesLog = divVotesLog;
          this.divVotesLog.innerHTML = "";
          let i = 0;
          for (i = 0; i < votes.data.votes.length; i ++) {
            this.showVote (votes.data.votes [i]);
          }
        }
      }
    },

    async updateCommentariesControls () {
      const divCommentariesControls = document.getElementById ("divCommentariesControls");
      if (divCommentariesControls) {
        const isLoggedIn = ClientsService.isLoggedIn ();
        divCommentariesControls.style.display = isLoggedIn ? "block" : "none";
      }
    },

    async updateCommentaries () {
      const btAddCommentary = document.getElementById ("btAddCommentary");
      const btUpdateCommentary = document.getElementById ("btUpdateCommentary");
      const btCancelUpdateCommentary = document.getElementById ("btCancelUpdateCommentary");
      const divCommentaries = document.getElementById ("divCommentaries");
      if (btAddCommentary && btUpdateCommentary && btCancelUpdateCommentary &&
          divCommentaries
      ) {
        btAddCommentary.style.display = "inline-block";
        btUpdateCommentary.style.display = "none";
        btCancelUpdateCommentary.style.display = "none";
        this.divCommentaries = divCommentaries;
        this.divCommentaries.innerHTML = "";
        const commentaries = await CommentariesService.getCommentaries (this.$route.params.id);
        if (commentaries) {
            let i = 0;
            for (i = 0; i < commentaries.length; i ++) {
              this.showCommentary (commentaries [i]);
            }
        }
      }
    },

    btUpdateImage_OnClick: async function () {
      const edImageName = document.getElementById ("edImageName") as HTMLInputElement;
      const edImageDescription = document.getElementById ("edImageDescription") as HTMLInputElement;
      const edImageLocation = document.getElementById ("edImageLocation") as HTMLInputElement;
      if (edImageName && edImageDescription && edImageLocation) {
        await ImagesService.update (
          this.$route.params.id,
          edImageName.value,
          edImageDescription.value,
          edImageLocation.value,
          []
        );
        await this.update ();
      }
    },
    btDeleteImage_OnClick: async function () {
      console.log ("Galleriy.btDeleteImage_OnClick () : Function has been called.");
      await ImagesService.delete (this.$route.params.id);
      const idGallery = LocalStorage.getValue ("idGallery");
      window.location.href = `/galleries/${idGallery}`;
    },

    async btUploadImage_OnClick () {
      const form = document.getElementById ("formImageUpload") as HTMLFormElement;
      if (form) {
        const dataForm = new FormData (form);
        await ImagesService.uploadImage (this.$route.params.id, dataForm);
        await this.updateImage ();
      }
    },

    async btAddVotePlus_OnClick () {
      await VotesService.add (
        this.$route.params.id,
        EEntityType.Image,
        EVotes.Plus
      );
      await this.updateVotes ();
    },

    async btAddVoteMinus_OnClick () {
      await VotesService.add (
        this.$route.params.id,
        EEntityType.Image,
        EVotes.Minus
      );
      await this.updateVotes ();
    },

    async btAddVoteComplain_OnClick () {
      await VotesService.add (
        this.$route.params.id,
        EEntityType.Image,
        EVotes.Complain
      );
      await this.updateVotes ();
    },

    async btAddCommentary_OnClick () {
      const textareaCommetary = document.getElementById ("textareaCommetary") as HTMLInputElement;
      if (textareaCommetary && textareaCommetary.value.trim ()) {
        await CommentariesService.add (
          this.$route.params.id,
          textareaCommetary.value.trim ()
        );
        await this.updateCommentaries ();
      }
    },

    async btUpdateCommentary_OnClick () {
      const textareaCommetary = document.getElementById ("textareaCommetary") as HTMLInputElement;
      const idCommentary = LocalStorage.getValue ("idCommentary");
      if (textareaCommetary && textareaCommetary.value.trim () &&
          idCommentary
      ) {
        await CommentariesService.update (
          idCommentary,
          this.$route.params.id,
          textareaCommetary.value.trim ()
        );
        textareaCommetary.value = "";
        await this.updateCommentaries ();
      }
    },

    async btCancelUpdateCommentary_OnClick () {
      const textareaCommetary = document.getElementById ("textareaCommetary") as HTMLInputElement;
      const btAddCommentary = document.getElementById ("btAddCommentary");
      const btUpdateCommentary = document.getElementById ("btUpdateCommentary");
      const btCancelUpdateCommentary = document.getElementById ("btCancelUpdateCommentary");
      if (textareaCommetary &&
          btAddCommentary && btUpdateCommentary && btCancelUpdateCommentary
      ) {
        LocalStorage.deleteValue ("idCommentary");
        btAddCommentary.style.display = "inline-block";
        btUpdateCommentary.style.display = "none";
        btCancelUpdateCommentary.style.display = "none";
        textareaCommetary.value = "";
      }
    },

    btShowUpdateCommentaryControls_OnClick (inputCommentaryId: string, inputCommentary: string) {
      const textareaCommetary = document.getElementById ("textareaCommetary") as HTMLInputElement;
      const btAddCommentary = document.getElementById ("btAddCommentary");
      const btUpdateCommentary = document.getElementById ("btUpdateCommentary");
      const btCancelUpdateCommentary = document.getElementById ("btCancelUpdateCommentary");
      if (textareaCommetary &&
          btAddCommentary && btUpdateCommentary && btCancelUpdateCommentary
      ) {
        LocalStorage.setValue ("idCommentary", inputCommentaryId);
        btAddCommentary.style.display = "none";
        btUpdateCommentary.style.display = "inline-block";
        btCancelUpdateCommentary.style.display = "inline-block";
        textareaCommetary.value = inputCommentary;
      }
    },

    async btDeleteCommentary_OnClick (inputCommentaryId: string) {
      // console.log (inputCommentaryId);
      await CommentariesService.delete (inputCommentaryId);
      await this.updateCommentaries ();
    },

    showVote (inputVote: IVote) {
      // console.log (inputCommentary);
      this.divVotesLog.insertAdjacentHTML ("beforeend",
        `<div class="MarginBottomSmall">
          <a href="/clients/${inputVote.idOwner}">${inputVote.nameOwner}</a> : 
          ${VotesService.getVoteString (inputVote.vote)} , 
          ${inputVote.dateCreation.toString ()}
        </div>`);
    },

    showCommentary (inputCommentary: ICommentary) {
      // console.log (inputCommentary);
      this.divCommentaries.insertAdjacentHTML ("beforeend",
        `<div class="MarginBottomSmall">
          <div><a href="/clients/${inputCommentary.idOwner}">${inputCommentary.nameOwner}</a></div>
          <div class="MarginTopUltraSmall">${inputCommentary.commentary}</div>
          ${inputCommentary.belongsToRequestingClient ? `<div>
            <input type="button" value="Обновить" class="MarginTopUltraSmall" onclick="btShowUpdateCommentaryControls_OnClick ('${inputCommentary.id}', '${inputCommentary.commentary}')"/>
            <input type="button" value="Удалить" class="MarginTopUltraSmall" onclick="btDeleteCommentary_OnClick ('${inputCommentary.id}')"/>
          </div>` : ""}
        </div>`);
    },

    async updateClient (inputClientId: string) {
      const client = await ClientsService.GetClient (inputClientId);
      if (!client) {
        return;
      }

      const linkClient = document.getElementById ("linkClient");
      if (linkClient) {
        linkClient.innerHTML = `${client.name} ${client.surname}`;
        linkClient.setAttribute ("href", `/clients/${inputClientId}`);
      }
    }
  },
})
export default class ComponentImage extends Vue {
}
