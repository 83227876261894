import axios from 'axios';

import {
	URL_DATA_PROCESSOR_VOTES,
    IMeta
} from '@/system/constants';

import {ClientsService} from '@/system/services/ClientsService';

export interface IVote {
    id: string;
    idOwner: string;
    nameOwner: string;
    idImage ? : string;
    idCommentary ? : string;
	vote: number;
    status: number;
    dateCreation: Date;
}

export interface IVotesQueryResult {
    data: {
        votes: IVote[];
        amountPlusVotes: number;
        amountMinusVotes: number;
        amountComplainsVotes: number;
        rating: number;
    }
    meta: IMeta;
}

export const EVotes = {
    Plus: 1,
    Minus: 2,
    Complain: 3
};

const g_stringsVotes = ["+", "-", "Жалоба"];

export class VotesService {
    static async add (
        inputEntityId: string,
        inputEntityType: number,
        inputVote: number
    ): Promise <void> {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.post(
                `${URL_DATA_PROCESSOR_VOTES}`,
                {
                    "idEntity": inputEntityId,
                    "typeEntity": inputEntityType,
                    "vote": inputVote
                },
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.vote
                ) {
                    console.error (`VotesService.add () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                resolve ();

            }).catch(function (error : any) {
                console.error(['VotesService.add () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`VotesService.add () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
    }

    static async getVotes (
        inputEntityId: string,
        inputEntityType: number
    ): Promise <IVotesQueryResult | null> {
        let result = null;
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.get(
                `${URL_DATA_PROCESSOR_VOTES}?idEntity=${inputEntityId}&typeEntity=${inputEntityType}`,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                // console.log (response.data);
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.votes
                ) {
                    console.error (`VotesService.getVotes () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                result = response.data;
                resolve ();

            }).catch(function (error : any) {
                console.error(['VotesService.getVotes () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`VotesService.getVotes () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);

        return result;
    }

    static async get (inputId: string): Promise <IVote | null> {
        let result = null;
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.get(
                `${URL_DATA_PROCESSOR_VOTES}${inputId}/`,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                // console.log (response.data);
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.vote
                ) {
                    console.error (`VotesService.get () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                result = response.data.data.vote;
                resolve ();

            }).catch(function (error : any) {
                console.error(['VotesService.get () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`VotesService.get () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);

        return result;
    }

    static async update (
        inputId: string,
        inputImageId: string,
        inputCommentary: string,
        inputReplyToCommentaryId: string | undefined = undefined
    ): Promise <void> {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.put (
                `${URL_DATA_PROCESSOR_VOTES}${inputId}/`,
                {
                    idImage: inputImageId,
                    idCommentaryReplyTo: inputReplyToCommentaryId,
                    vote: inputCommentary
                },
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data) {
                    console.error (`VotesService.update () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                resolve ();

            }).catch(function (error : any) {
                console.error(['VotesService.update () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`VotesService.update () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
    }

    static async delete (inputId: string): Promise <void> {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.delete (
                `${URL_DATA_PROCESSOR_VOTES}${inputId}/`,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data) {
                    console.error (`VotesService.delete () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                resolve ();

            }).catch(function (error : any) {
                console.error(['VotesService.delete () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`VotesService.delete () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
    }



    static getVoteString (inputVoteIndex: number): string {
        if (inputVoteIndex < 1 || inputVoteIndex > g_stringsVotes.length) {
            return "";
        }
        return g_stringsVotes [inputVoteIndex - 1];
    }
}