
import {Options, Vue} from 'vue-class-component'
import ComponentImage from '@/components/Image.vue'

@Options({
  components: {
    ComponentImage
  },

  mounted () {
    
  }
})
export default class Image extends Vue {}
