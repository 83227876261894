import axios from 'axios';

import {
	URL_DATA_PROCESSOR_COMMENTARIES
} from '@/system/constants';

import {ClientsService} from '@/system/services/ClientsService';

export interface ICommentary {
    id: string;
    idOwner: string;
    nameOwner: string;
    belongsToRequestingClient: boolean;
    idImage: string;
    idCommentaryReplyTo ? : string;
	commentary: string;
    status: number;
}

export class CommentariesService {
    static async add (
        inputImageId: string,
        inputCommentary: string,
        inputReplyToCommentaryId: string | undefined = undefined
    ): Promise <void> {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.post(
                `${URL_DATA_PROCESSOR_COMMENTARIES}`,
                {
                    "idImage": inputImageId,
                    "idCommentaryReplyTo": inputReplyToCommentaryId,
                    "commentary": inputCommentary
                },
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.commentary
                ) {
                    console.error (`CommentariesService.add () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                resolve ();

            }).catch(function (error : any) {
                console.error(['CommentariesService.add () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`CommentariesService.add () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
    }

    static async getCommentaries (inputImageId: string): Promise <ICommentary [] | null> {
        let result = null;
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.get(
                `${URL_DATA_PROCESSOR_COMMENTARIES}?idImage=${inputImageId}`,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                // console.log (response.data);
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.commentaries
                ) {
                    console.error (`CommentariesService.getCommentaries () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                result = response.data.data.commentaries;
                resolve ();

            }).catch(function (error : any) {
                console.error(['CommentariesService.getCommentaries () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`CommentariesService.getCommentaries () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);

        return result;
    }

    static async get (inputId: string): Promise <ICommentary | null> {
        let result = null;
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.get(
                `${URL_DATA_PROCESSOR_COMMENTARIES}${inputId}/`,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                // console.log (response.data);
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.commentary
                ) {
                    console.error (`CommentariesService.get () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                result = response.data.data.commentary;
                resolve ();

            }).catch(function (error : any) {
                console.error(['CommentariesService.get () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`CommentariesService.get () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);

        return result;
    }

    static async update (
        inputId: string,
        inputImageId: string,
        inputCommentary: string,
        inputReplyToCommentaryId: string | undefined = undefined
    ): Promise <void> {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.put (
                `${URL_DATA_PROCESSOR_COMMENTARIES}${inputId}/`,
                {
                    idImage: inputImageId,
                    idCommentaryReplyTo: inputReplyToCommentaryId,
                    commentary: inputCommentary
                },
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data) {
                    console.error (`CommentariesService.update () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                resolve ();

            }).catch(function (error : any) {
                console.error(['CommentariesService.update () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`CommentariesService.update () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
    }

    static async delete (inputId: string): Promise <void> {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.delete (
                `${URL_DATA_PROCESSOR_COMMENTARIES}${inputId}/`,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data) {
                    console.error (`CommentariesService.delete () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                resolve ();

            }).catch(function (error : any) {
                console.error(['CommentariesService.delete () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`CommentariesService.delete () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
    }
}